import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public showSplashScreen: boolean = true;

  public ngOnInit(): void {
    setTimeout(() => this.showSplashScreen = false, 1000);
  }
}
