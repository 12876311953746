import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';

// import {MatSnackBar} from '@angular/material';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    public router: Router,
    // public notifier: MatSnackBar,
  ) {
  }

  private handleError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401) {
      this.router.navigate(['enter-phone']).then();
      // this.notifier.open('Session has expired. Please, login again!');
    }
    throw err;
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = {};
    const headersKeys = req.headers.keys();
    headersKeys.forEach((key) => {
      headers[key] = req.headers.get(key);
    });

    const token = localStorage.getItem('accessToken');
    if (!!token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    req = req.clone({setHeaders: headers});

    return next.handle(req).pipe(
      catchError((err) => {
        this.handleError(err);
        return of(err);
      })
    );
  }
}
